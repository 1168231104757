import React, { useEffect, useState } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { quotes } from "../assets/quotes.js"; // An array of quotes from Indian writers

const LoadingScreen = () => {
  const [quote, setQuote] = useState("");

  useEffect(() => {
    // Set a random quote when the component mounts
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Translucent background
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff", // White text
        zIndex: 9999, // Ensure it's on top
        textAlign: "center",
        fontFamily: "Montserrat, Helvetica, sans-serif",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Thanks, On it...
        <br />
        This might take a few moments. Thanks for your patience!
      </Typography>

      <Box sx={{ width: "80%", maxWidth: "400px", mb: 2 }}>
        <LinearProgress color="secondary" /> {/* Loading Bar */}
      </Box>

      <Typography variant="body1" sx={{ mt: 2, fontStyle: "italic" }}>
        {quote}
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
