import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom"; // Add useNavigate for navigation
import axios from "axios";
import LoaderWithThought from "../components/LoaderWithThought"; // Import your custom loader
import API_ENDPOINTS from "../config/apiconfig";

const Modal2 = ({ open, onClose, quizData, threadToken }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nextQuizData, setNextQuizData] = useState(quizData); // Local state to hold current quiz data
  const navigate = useNavigate(); // For navigation

  // Extract the message from the quizData prop
  const message = nextQuizData?.message || "";

  // Update local state when quizData changes
  useEffect(() => {
    if (quizData) {
      setNextQuizData(quizData);
    }
  }, [quizData]);

  // Parse the message for options
  const parseMessage = (message) => {
    const lines = message.split("\n");
    const optionsStartIndex = lines.findIndex(
      (line) =>
        line.trim().startsWith("a)") ||
        line.trim().startsWith("b)") ||
        line.trim().startsWith("c)") ||
        line.trim().startsWith("d)") ||
        line.trim().startsWith("1.") ||
        line.trim().startsWith("2.") ||
        line.trim().startsWith("3.") ||
        line.trim().startsWith("4.") ||
        line.trim().startsWith("A)") ||
        line.trim().startsWith("B)") ||
        line.trim().startsWith("C)") ||
        line.trim().startsWith("D)")
    );

    if (optionsStartIndex === -1) {
      return { question: message, options: [] };
    }

    const question = lines.slice(0, optionsStartIndex).join("\n").trim();
    const options = lines
      .slice(optionsStartIndex)
      .filter((line) => line.trim() !== "")
      .map((line) => line.trim());

    return { question, options };
  };

  const { question, options } = parseMessage(message);

  // Handle option click
  const handleOptionClick = (option) => {
    const selectedCharacter = option.trim().charAt(0);
    setSelectedOption(selectedCharacter);
  };

  // Handle 'Next' button click
  const handleNextClick = async () => {
    if (selectedOption && threadToken) {
      setLoading(true);

      const requestBody = new URLSearchParams({
        astName: "Edu Assess",
        apiToken:
          "2ee492bb8ad2d635cb0d1bd5fc2a03e24cf9a0bfbfcccb0c039653cd03e2714d",
        message: selectedOption, // Send the selected option
        threadtoken: threadToken, // Send the thread token to continue the quiz
      });

      try {
        const response = await axios.post(
          API_ENDPOINTS.quizGeneratorAPI,
          requestBody,
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );

        const data = response.data;

        if (data.status && data.data.length > 0) {
          const questionData = data.data[0][0];

          if (
            questionData.message &&
            typeof questionData.message === "string"
          ) {
            // Check if the message contains the end-of-quiz indicator
            if (questionData.message.includes("Assessment")) {
              // Navigate to result page
              navigate("/result", {
                state: { quizResult: questionData.message },
              });
            } else {
              // Otherwise, set the next quiz question
              setNextQuizData({ message: questionData.message });
              setSelectedOption(null);
            }
          } else {
            console.error("Invalid message format or null");
          }
        } else {
          console.warn("No quiz data received");
        }
      } catch (error) {
        console.error("Error fetching next quiz data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} style={styles.modalContainer}>
      <Box
        className={open ? "modalSlideIn" : "modalSlideOut"}
        style={styles.modalBox}
      >
        <div style={styles.header}>
          <Typography variant="h6" component="h2" style={styles.heading}>
            Grading Assessment
          </Typography>
          <IconButton onClick={onClose} style={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <hr style={styles.separator} />

        {/* Display LoaderWithThought when loading */}
        {loading ? (
          <div style={styles.loaderContainer}>
            <LoaderWithThought />
          </div>
        ) : (
          <div style={styles.quizSection}>
            <ReactMarkdown
              children={question}
              components={{
                h1: ({ node, ...props }) => (
                  <Typography variant="h4" {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <Typography variant="h5" {...props} />
                ),
                h3: ({ node, ...props }) => (
                  <Typography variant="h6" {...props} />
                ),
                p: ({ node, ...props }) => <Typography paragraph {...props} />,
              }}
            />
            <div style={styles.optionsContainer}>
              {options.map((option, index) => (
                <Card
                  key={index}
                  style={{
                    ...styles.optionCard,
                    backgroundColor:
                      selectedOption === option.trim().charAt(0)
                        ? "#714B90"
                        : "white",
                    color:
                      selectedOption === option.trim().charAt(0)
                        ? "white"
                        : "black",
                  }}
                  onClick={() => handleOptionClick(option)}
                >
                  <CardContent>
                    <Typography variant="body1">{option}</Typography>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        )}

        {/* Render buttons only when not loading */}
        {!loading && (
          <div style={styles.footer}>
            <Button
              variant="outlined"
              style={{
                ...styles.navButton,
                color: "#714B90",
                border: "1px solid #714B90",
                backgroundColor: "white",
              }}
              disabled
            >
              Previous
            </Button>
            <Button
              variant="contained"
              style={{
                ...styles.navButton,
                backgroundColor: "#714B90",
                color: "white",
              }}
              onClick={handleNextClick}
              disabled={!selectedOption}
            >
              Next
            </Button>
          </div>
        )}
      </Box>
    </Modal>
  );
};

const styles = {
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  modalBox: {
    width: 500,
    height: 600,
    backgroundColor: "white",
    padding: "16px",
    boxShadow: 24,
    outline: "none",
    borderRadius: 12,
    fontFamily: "Montserrat, Helvetica, sans-serif",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  separator: {
    border: "none",
    borderTop: "1px solid #ddd",
    margin: "16px 0",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px", // Adjust height as necessary
    paddingTop: "20px",
    marginTop: "20px",
    color: "#714B90",
  },
  quizSection: {
    flex: 1,
    overflowY: "auto",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  optionCard: {
    cursor: "pointer",
    padding: "8px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "background-color 0.2s ease",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  navButton: {
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
};

export default Modal2;
