import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Radio,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Rahul from "../assets/Rahul.png";
import Priya from "../assets/Priya.png";
import Modal2 from "./Modal2";
import "../styles/modalTransitions.css";
import axios from "axios";
import LoaderWithThought from "../components/LoaderWithThought"; // Import your custom loader
import Keshav from "../assets/Keshav.png";
import Kritika from "../assets/Kritika.png";
import Sonu from "../assets/Sonu.png";
import API_ENDPOINTS from "../config/apiconfig";

const Modal1 = ({ open, onClose }) => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [threadToken, setThreadToken] = useState(""); // Thread token state to be used in Modal2
  const [age, setAge] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle student selection
  const handleRadioChange = (event) => {
    const student = event.target.value;
    setSelectedStudent(student);

    // Set age based on student selection
    if (student === "Rahul Kumar") {
      setAge(5);
    } else if (student === "Priya Kumari") {
      setAge(6);
    } else if (student === "Keshav Kumar") {
      setAge(6);
    } else if (student === "Sonu Yadav") {
      setAge(7);
    } else if (student === "Kritika Kumari") {
      setAge(8);
    }
  };

  // Proceed button logic with API call
  const handleProceed = async () => {
    if (age === null) {
      console.error("Age is not set");
      return;
    }

    setLoading(true);

    try {
      // API call to fetch quiz data
      const response = await axios.post(
        API_ENDPOINTS.quizGeneratorAPI,
        new URLSearchParams({
          astName: "Edu Assess",
          apiToken:
            "2ee492bb8ad2d635cb0d1bd5fc2a03e24cf9a0bfbfcccb0c039653cd03e2714d",
          message: age.toString(),
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      const data = response.data;
      console.log("API Response:", data);

      // Check if data is received and process it
      if (data.status && data.data.length > 0) {
        const questionData = data.data[0][0];
        console.log("Question Data:", questionData);

        // Prepare data for Modal2 and pass threadToken
        if (questionData.message && typeof questionData.message === "string") {
          setQuizData({
            message: questionData.message,
            threadToken: data.thread_data[0].threadToken,
          });

          setThreadToken(data.thread_data[0].threadToken);
          setShowModal2(true); // Show Modal2 after receiving quiz data
        } else {
          console.error("Invalid message format or null");
        }
      } else {
        console.warn("No quiz data received");
      }
    } catch (error) {
      console.error("Error sending age to API:", error);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} style={styles.modalContainer}>
        <Box
          className={open ? "modalSlideIn" : "modalSlideOut"}
          style={styles.modalBox}
        >
          <div style={styles.header}>
            <Typography variant="h6" component="h2" style={styles.heading}>
              Select Student
            </Typography>
            <IconButton onClick={onClose} style={styles.closeButton}>
              <CloseIcon />
            </IconButton>
          </div>
          <hr style={styles.separator} />
          <div style={styles.studentList}>
            <div style={styles.studentItem}>
              <div style={styles.studentInfo}>
                <img
                  src={Rahul}
                  alt="Rahul Kumar"
                  style={styles.studentImage}
                />
                <div style={styles.studentDetails}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={styles.studentName}
                  >
                    Rahul Kumar
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    style={styles.studentAge}
                  >
                    Age: 5 years
                  </Typography>
                </div>
                <Radio
                  checked={selectedStudent === "Rahul Kumar"}
                  onChange={handleRadioChange}
                  value="Rahul Kumar"
                  style={styles.radioButton}
                />
              </div>
              <hr style={styles.studentSeparator} />
            </div>
            <div style={styles.studentItem}>
              <div style={styles.studentInfo}>
                <img
                  src={Priya}
                  alt="Priya Kumari"
                  style={styles.studentImage}
                />
                <div style={styles.studentDetails}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={styles.studentName}
                  >
                    Priya Kumari
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    style={styles.studentAge}
                  >
                    Age: 6 years
                  </Typography>
                </div>
                <Radio
                  checked={selectedStudent === "Priya Kumari"}
                  onChange={handleRadioChange}
                  value="Priya Kumari"
                  style={styles.radioButton}
                />
              </div>
              <hr style={styles.studentSeparator} />
            </div>
            <div style={styles.studentItem}>
              <div style={styles.studentInfo}>
                <img
                  src={Keshav}
                  alt="Keshav Kumar"
                  style={styles.studentImage}
                />
                <div style={styles.studentDetails}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={styles.studentName}
                  >
                    Keshav Kumar
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    style={styles.studentAge}
                  >
                    Age: 6 years
                  </Typography>
                </div>
                <Radio
                  checked={selectedStudent === "Keshav Kumar"}
                  onChange={handleRadioChange}
                  value="Keshav Kumar"
                  style={styles.radioButton}
                />
              </div>
              <hr style={styles.studentSeparator} />
            </div>
            <div style={styles.studentItem}>
              <div style={styles.studentInfo}>
                <img src={Sonu} alt="Sonu Yadav" style={styles.studentImage} />
                <div style={styles.studentDetails}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={styles.studentName}
                  >
                    Sonu Yadav
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    style={styles.studentAge}
                  >
                    Age: 7 years
                  </Typography>
                </div>
                <Radio
                  checked={selectedStudent === "Sonu Yadav"}
                  onChange={handleRadioChange}
                  value="Sonu Yadav"
                  style={styles.radioButton}
                />
              </div>
              <hr style={styles.studentSeparator} />
            </div>
            <div style={styles.studentItem}>
              <div style={styles.studentInfo}>
                <img src={Kritika} alt="Kritika" style={styles.studentImage} />
                <div style={styles.studentDetails}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={styles.studentName}
                  >
                    Kritika Kumari
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    style={styles.studentAge}
                  >
                    Age: 8 years
                  </Typography>
                </div>
                <Radio
                  checked={selectedStudent === "Kritika Kumari"}
                  onChange={handleRadioChange}
                  value="Kritika Kumari"
                  style={styles.radioButton}
                />
              </div>
              <hr style={styles.studentSeparator} />
            </div>
          </div>

          {loading ? (
            <div style={styles.loaderContainer}>
              {/* Use the LoaderWithThought here */}
              <LoaderWithThought />
            </div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={styles.proceedButton}
              onClick={handleProceed}
              disabled={selectedStudent === null}
            >
              Proceed
            </Button>
          )}
        </Box>
      </Modal>

      {/* Modal2 for showing quiz */}
      <Modal2
        open={showModal2}
        onClose={() => setShowModal2(false)}
        quizData={quizData}
        threadToken={threadToken} // Pass threadToken to Modal2
      />
    </>
  );
};

const styles = {
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBox: {
    width: 500,
    height: 600,
    backgroundColor: "white",
    padding: "16px",
    boxShadow: 24,
    outline: "none",
    borderRadius: 12,
    fontFamily: "Montserrat, Helvetica, sans-serif",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  heading: {
    margin: 0,
  },
  closeButton: {
    padding: 0,
  },
  separator: {
    border: "none",
    borderTop: "1px solid #ddd",
    margin: "16px 0",
  },
  studentList: {
    flex: 1,
    overflowY: "auto",
    marginTop: "-5px",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  studentItem: {
    marginBottom: 16,
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  studentInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  studentImage: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    marginRight: 16,
  },
  studentDetails: {
    flex: 1,
  },
  studentName: {
    fontSize: ".875rem",
    fontWeight: "bold",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  studentAge: {
    fontSize: "0.675rem",
    color: "#555",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  studentSeparator: {
    border: "none",
    borderTop: "1px solid #ddd",
    margin: "8px 0",
  },
  radioButton: {
    marginLeft: 16,
    color: "#714B90",
  },
  proceedButton: {
    marginTop: 16,
    alignSelf: "center",
    width: "95%",
    position: "absolute",
    bottom: 16,
    backgroundColor: "#714B90",
    fontFamily: "Montserrat, Helvetica, sans-serif",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 12,
    color: "#714B90",
  },
};

export default Modal1;
