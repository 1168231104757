export const quotes = [
  "शिक्षा वह शस्त्र है जिसका उपयोग आप दुनिया को बदलने के लिए कर सकते हैं।",
  "The purpose of education is to make good human beings with skill and expertise.",
  "Learning gives creativity, creativity leads to thinking, thinking provides knowledge, and knowledge makes you great.",
  "ज्ञान से ज्यादा महत्वपूर्ण चीज होती है कल्पना।",
  "विद्या ददाति विनयं।",
  "The highest education is that which does not merely give us information but makes our life in harmony with all existence. – Rabindranath Tagore",
  "शिक्षा सबसे अच्छी मित्र है।",
  "शिक्षा का उद्देश्य आत्मनिर्भरता और जागरूकता है।",
  "सच्ची शिक्षा वही है, जो मनुष्य को उसके कर्तव्यों का ज्ञान कराती है।",
  "शिक्षा केवल ज्ञान का संचय नहीं, बल्कि विचारों का विकास भी है।",
  "शिक्षा आत्मा का विकास करती है और समाज को समृद्ध बनाती है।",
  "किसी भी मनुष्य का असली ज्ञान उसके व्यवहार में दिखता है।",
  "शिक्षा वह कुंजी है जो बंद दरवाजों को खोलती है।",
  "Education is not just about acquiring knowledge; it’s about developing a mindset for lifelong learning.",
  "The roots of education are bitter, but the fruit is sweet.",
  "Education is the most powerful weapon which you can use to change the world.",
  "The mind is not a vessel to be filled but a fire to be kindled.",
  "Education is the key to unlock the golden door of freedom."
];
