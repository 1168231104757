import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dropdownData from "../assets/dropdown.json"; // Adjust path as needed
import LoadingScreen from "../components/LoadingScreen"; // Import the custom loading screen
import API_ENDPOINTS from "../config/apiconfig";

const Modal3 = ({ open, onClose }) => {
  const [formType, setFormType] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [chapterNumber, setChapterNumber] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setFormType("");
    setLoading(false);
    setVideoUrl("");
    setGrade("");
    setSubject("");
    setChapterNumber("");
    setChapterName("");
    onClose();
  };

  const handleSubmitSubject = async () => {
    const apiUrl = API_ENDPOINTS.lessonPlanGen;
    const queryParams = `?grade=${grade}&subject=${subject}&chapter_number=${chapterNumber}&chapter_name=${chapterName}`;

    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}${queryParams}`);
      const lessonPlan = response.data;

      navigate("/lesson-plan-generator", { state: { lessonPlan } });
    } catch (error) {
      console.error("Error generating lesson plan:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitVideo = async () => {
    const apiUrl = API_ENDPOINTS.lessonPlanGenVideo;
    const queryParams = `?url=${encodeURIComponent(videoUrl)}`;

    setLoading(true);
    try {
      const response = await axios.post(`${apiUrl}${queryParams}`, {});
      const lessonPlanURL = response.data;

      navigate("/lesson-plan-generator", { state: { lessonPlanURL } });
    } catch (error) {
      console.error("Error generating lesson plan from video:", error);
    } finally {
      setLoading(false);
    }
  };

  const getSubjects = () => {
    const gradeData = dropdownData.grades.find(
      (g) => g.grade === parseInt(grade)
    );
    return gradeData ? gradeData.subjects : [];
  };

  const getChapters = () => {
    const gradeData = dropdownData.grades.find(
      (g) => g.grade === parseInt(grade)
    );
    if (gradeData) {
      const subjectData = gradeData.subjects.find((s) => s.subject === subject);
      return subjectData ? subjectData.chapters : [];
    }
    return [];
  };

  const getChapterName = (chapterNumber) => {
    const chapters = getChapters();
    const selectedChapter = chapters.find(
      (ch) => ch.chapterNo === parseInt(chapterNumber)
    );
    return selectedChapter ? selectedChapter.chapterName : "";
  };

  const handleChapterNumberChange = (e) => {
    const value = e.target.value;
    setChapterNumber(value);
    setChapterName(getChapterName(value));
  };

  return (
    <>
      {/* Show LoadingScreen if loading */}
      {loading && <LoadingScreen />}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
            {formType === "video"
              ? "Enter your video URL"
              : formType === "subject"
              ? "Enter details for lesson plan generation"
              : "Select type of lesson plan to generate"}
          </Typography>

          {formType === "" && (
            <>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#714B90", mb: 2, width: "100%" }}
                onClick={() => setFormType("video")}
              >
                Generate lesson plan using the video
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#4CAF50", width: "100%" }}
                onClick={() => setFormType("subject")}
              >
                Generate using subject or chapter name
              </Button>
            </>
          )}

          {formType === "video" && (
            <>
              <TextField
                label="Enter your video URL"
                placeholder="https://example.com/video"
                fullWidth
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                sx={{ backgroundColor: "#714B90", width: "100%" }}
                onClick={handleSubmitVideo}
                disabled={loading}
              >
                Submit
              </Button>
            </>
          )}

          {formType === "subject" && (
            <>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  variant="filled"
                >
                  {dropdownData.grades.map((g) => (
                    <MenuItem key={g.grade} value={g.grade}>
                      {g.grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Subject</InputLabel>
                <Select
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  disabled={!grade}
                  variant="filled"
                >
                  {getSubjects().map((sub) => (
                    <MenuItem key={sub.subject} value={sub.subject}>
                      {sub.subject}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Chapter Number</InputLabel>
                <Select
                  value={chapterNumber}
                  onChange={handleChapterNumberChange}
                  disabled={!subject}
                  variant="filled"
                >
                  {getChapters().map((ch) => (
                    <MenuItem key={ch.chapterNo} value={ch.chapterNo}>
                      {ch.chapterNo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Chapter Name</InputLabel>
                <Select
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                  disabled={!chapterNumber}
                  variant="filled"
                >
                  <MenuItem value={chapterName}>{chapterName}</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                sx={{ backgroundColor: "#4CAF50", width: "100%" }}
                onClick={handleSubmitSubject}
                disabled={loading}
              >
                Submit
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Modal3;
