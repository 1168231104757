// import React from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Paper,
//   IconButton,
// } from "@mui/material";
// import LoginHeader from "./LoginHeader"; // Assuming you will use the header we created earlier
// import LoginImage from "../assets/LoginImage.png"; // Ensure this path is correct
// import GoogleImage from "../assets/google.svg"; // Ensure this path is correct
// import FacebookImage from "../assets/facebook.svg"; // Ensure this path is correct
// import TwitterImage from "../assets/twitter.svg"; // Ensure this path is correct

// const LoginPage = () => {
//   return (
//     <Box
//       sx={{
//         height: "100vh",
//         backgroundColor: "#ffffff",
//         backgroundImage: `url(${LoginImage})`, // Set the background image
//         backgroundSize: "cover", // Ensure the image covers the area
//         backgroundPosition: "center", // Center the image
//         display: "flex",
//         flexDirection: "column",
//         marginTop: "64px",
//       }}
//     >
//       {/* Login Header */}
//       <Box sx={{ height: "64px", width: "100%" }}>
//         <LoginHeader />
//       </Box>

//       {/* Container for Login Form */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "flex-end", // Align to the right
//           flexGrow: 1, // Allow it to take the remaining height
//           paddingRight: "20px", // Add some right padding
//         }}
//       >
//         <Paper
//           elevation={3}
//           sx={{
//             padding: 4,
//             marginTop: "-64px",
//             marginRight: "-20px",
//             width: "640px", // Set a fixed width for the login box
//             backgroundColor: "white", // White background for the login form
//             // borderRadius: 2, // Rounded corners
//             boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
//           }}
//         >
//           <Typography variant="h5" sx={{ textAlign: "left", mb: 6, mt: 8 }}>
//             Teacher Login
//             <br />
//             <br />
//             <Typography
//               variant="p"
//               sx={{ textAlign: "left", fontSize: "14px" }}
//             >
//               New User?{" "}
//               <a href="Create Account" style={{ color: "#714B90" }}>
//                 Create Account
//               </a>
//             </Typography>
//           </Typography>
//           <form>
//             <TextField
//               fullWidth
//               label="Email Address"
//               variant="outlined"
//               margin="normal"
//               color="#714B90"
//               required
//             />
//             <TextField
//               fullWidth
//               label="Password"
//               variant="outlined"
//               margin="normal"
//               type="password"
//               color="#714B90"
//               required
//             />
//             <Box mt={3} display="flex" justifyContent="center">
//               <Button
//                 type="submit"
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#714B90",
//                   ":hover": { backgroundColor: "#5e3d74" },
//                   width: "100%",
//                   padding: "10px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Login
//               </Button>
//             </Box>
//             <Typography
//               variant="p"
//               style={{ color: "#714B90" }}
//               sx={{ textAlign: "right", fontSize: "14px" }}
//             >
//               <br />
//               <a href="Forgot Password">Forgot Password?</a>
//             </Typography>
//             <br />
//             <br />
//             <hr
//               style={{
//                 border: "none",
//                 borderTop: "1px solid grey",
//                 margin: "10px 0",
//               }}
//             />
//             <br />
//             <Box
//               sx={{
//                 display: "flex", // Enable flexbox
//                 justifyContent: "center", // Center items horizontally
//                 gap: "20px", // Space between icons
//                 margin: "20px 0", // Add some margin
//               }}
//             >
//             <IconButton
//               sx={{
//                 backgroundColor: "white",
//                 color: "white",
//                 borderRadius: "50%",
//                 padding: "10px",
//               }}
//             >
//               <img
//                 src={GoogleImage}
//                 alt="Google"
//                 style={{ width: "24px", height: "24px" }}
//               />
//             </IconButton>

//             <IconButton
//               sx={{
//                 backgroundColor: "white",
//                 color: "white",
//                 borderRadius: "50%",
//                 padding: "10px",
//               }}
//             >
//               <img
//                 src={FacebookImage}
//                 alt="Facebook"
//                 style={{ width: "24px", height: "24px" }}
//               />
//             </IconButton>

//             <IconButton
//               sx={{
//                 backgroundColor: "white",
//                 color: "white",
//                 borderRadius: "50%",
//                 padding: "10px",
//               }}
//             >
//               <img
//                 src={TwitterImage}
//                 alt="Twitter"
//                 style={{ width: "24px", height: "24px" }}
//               />
//             </IconButton>
//             </Box>
//           </form>
//         </Paper>
//       </Box>
//     </Box>
//   );
// };
// export default LoginPage;

import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import LoginHeader from "./LoginHeader"; // Assuming you will use the header we created earlier
import LoginImage from "../assets/LoginImage.png"; // Ensure this path is correct
import GoogleImage from "../assets/google.svg"; // Ensure this path is correct
import FacebookImage from "../assets/facebook.svg"; // Ensure this path is correct
import TwitterImage from "../assets/twitter.svg"; // Ensure this path is correct

const LoginPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "#ffffff",
        backgroundImage: `url(${LoginImage})`, // Set the background image
        backgroundSize: "cover", // Ensure the image covers the area
        backgroundPosition: "center", // Center the image
        display: "flex",
        flexDirection: "column",
        marginTop: { xs: "32px", md: "64px" }, // Responsive margin
      }}
    >
      {/* Login Header */}
      <Box sx={{ height: "64px", width: "100%" }}>
        <LoginHeader />
      </Box>

      {/* Container for Login Form */}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" }, // Center on mobile, align right on larger screens
          flexGrow: 1, // Allow it to take the remaining height
          paddingRight: { xs: "0", md: "20px" }, // No padding on mobile
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: { xs: 2, md: 4 }, // Adjust padding for mobile and desktop
            marginTop: { xs: "16px", md: "-64px" }, // Different top margin for mobile
            marginRight: { xs: "0", md: "-20px" }, // No margin on mobile
            width: { xs: "90%", sm: "75%", md: "640px" }, // Responsive width
            backgroundColor: "white", // White background for the login form
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Optional shadow for better visibility
          }}
        >
          <Typography
            variant="h5"
            sx={{ textAlign: "left", mb: 6, mt: { xs: 4, md: 8 } }}
          >
            Teacher Login
            <br />
            <br />
            <Typography
              variant="body2" // Changed from "p" to valid variant
              sx={{ textAlign: "left", fontSize: "14px" }}
            >
              New User?{" "}
              <a href="Create Account" style={{ color: "#714B90" }}>
                Create Account
              </a>
            </Typography>
          </Typography>
          <form>
            <TextField
              fullWidth
              label="Email Address"
              variant="outlined"
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              margin="normal"
              type="password"
              required
            />
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#714B90",
                  ":hover": { backgroundColor: "#5e3d74" },
                  width: "100%",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                Login
              </Button>
            </Box>
            <Typography
              variant="body2" // Changed from "p" to valid variant
              style={{ color: "#714B90" }}
              sx={{ textAlign: "right", fontSize: "14px", mt: 2 }}
            >
              <a href="Forgot Password">Forgot Password?</a>
            </Typography>
            <br />
            <hr
              style={{
                border: "none",
                borderTop: "1px solid grey",
                margin: "10px 0",
              }}
            />
            <br />
            <Box
              sx={{
                display: "flex", // Enable flexbox
                justifyContent: "center", // Center items horizontally
                gap: "20px", // Space between icons
                margin: "20px 0", // Add some margin
              }}
            >
              <IconButton
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "50%",
                  padding: "10px",
                }}
              >
                <img
                  src={GoogleImage}
                  alt="Google"
                  style={{ width: "24px", height: "24px" }}
                />
              </IconButton>

              <IconButton
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "50%",
                  padding: "10px",
                }}
              >
                <img
                  src={FacebookImage}
                  alt="Facebook"
                  style={{ width: "24px", height: "24px" }}
                />
              </IconButton>

              <IconButton
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "50%",
                  padding: "10px",
                }}
              >
                <img
                  src={TwitterImage}
                  alt="Twitter"
                  style={{ width: "24px", height: "24px" }}
                />
              </IconButton>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};
export default LoginPage;
