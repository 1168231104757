import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import ReactMarkdown from "react-markdown"; // Import react-markdown
import html2pdf from "html2pdf.js"; // For exporting as PDF
import Header from "./Header";
import Logo from "../assets/Logo.png"; // Import your logo image

const ExamGenerator = () => {
  const location = useLocation();
  const navigate = useNavigate(); // For navigating back to home page

  const contentToExportRef = useRef(null); // Ref for the content to export as PDF

  // Extract the message from the response
  const examData = location.state?.examData;
  const message = examData?.data || "No exam data available";

  // Handle navigation to home
  const handleGoHome = () => {
    navigate("/");
  };

  // Function to handle PDF export
  const handleExportAsPDF = () => {
    const element = contentToExportRef.current; // Get the content from ref

    // Dynamically add the logo to the top of the content
    const logoElement = document.createElement("img");
    logoElement.src = Logo;
    logoElement.alt = "Logo";
    logoElement.style.width = "150px";
    logoElement.style.marginBottom = "20px";

    // Insert the logo at the top of the content
    element.prepend(logoElement);

    const options = {
      margin: 0.5,
      filename: "exam_data.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Export the content as a PDF
    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => {
        // Remove the logo after export
        element.removeChild(logoElement);
      });
  };

  return (
    <div
      style={{
        paddingTop: "64px", // Top padding
        backgroundColor: "#FEFBF4", // As per your previous color style
        minHeight: "100vh", // Ensures the page takes full height
        padding: "20px",
      }}
    >
      <Header />
      <Typography
        variant="h4"
        component="h1"
        sx={{ mb: 3, textAlign: "center" }}
      >
        Exam Paper Data
      </Typography>

      {/* Content to export as PDF */}
      <Box sx={{ mb: 3, padding: 2 }} ref={contentToExportRef}>
        {/* Display the message using react-markdown */}
        <ReactMarkdown>{message}</ReactMarkdown>
      </Box>

      {/* Buttons to interact with */}
      <Box sx={{ textAlign: "center", mb: 2 }}>
        {/* Button to go to the Home Page */}
        <Button
          variant="contained"
          sx={{ backgroundColor: "#714B90", mb: 2 }}
          onClick={handleGoHome}
        >
          Go to Home Page
        </Button>

        {/* Export as PDF Button */}
        <Button
          variant="contained"
          color="secondary"
          sx={{ mb: 2, ml: 2 }}
          onClick={handleExportAsPDF}
        >
          Export to PDF
        </Button>

        {/* Save and Download Button (inactive) */}
        <Button
          variant="contained"
          color="warning"
          sx={{ ml: 2, mb: 2 }}
          disabled
        >
          Save and Download
        </Button>
      </Box>
    </div>
  );
};

export default ExamGenerator;
