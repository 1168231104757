import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dropdownData from "../assets/dropdown.json"; // Adjust path as necessary
import LoadingScreen from "./LoadingScreen"; // Adjust import path
import API_ENDPOINTS from "../config/apiconfig";

const Modal4 = ({ open, onClose }) => {
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Reset the modal form
  const handleClose = () => {
    setGrade("");
    setSubject("");
    setDifficulty("");
    setChapters([]);
    setLoading(false);
    onClose();
  };

  // Handle adding a new chapter
  const handleAddChapter = () => {
    setChapters([
      ...chapters,
      {
        chapterNumber: "",
        mcq: "",
        shortAnswer: "",
        longAnswer: "",
        weightage: "",
      },
    ]);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);

    const chapterData = chapters.map((ch) => ({
      chapter_name: getChapterName(ch.chapterNumber),
      chapter_number: ch.chapterNumber,
      weightage: ch.weightage,
      question_types: {
        mcq: ch.mcq || 0,
        short_answer: ch.shortAnswer || 0,
        long_answer: ch.longAnswer || 0,
      },
    }));

    const examData = {
      class_name: String(grade),
      subject,
      chapters: chapterData,
      difficulty,
    };

    try {
      const response = await axios.post(
        API_ENDPOINTS.examGeneratorAPI,
        examData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      navigate("/exam-generator", { state: { examData: response.data } });
    } catch (error) {
      console.error("Error generating exam paper:", error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  // Get subjects based on the selected grade
  const getSubjects = () => {
    const gradeData = dropdownData.grades.find(
      (g) => g.grade === parseInt(grade)
    );
    return gradeData ? gradeData.subjects : [];
  };

  // Get chapter names and numbers based on the selected grade and subject
  const getChapters = () => {
    const gradeData = dropdownData.grades.find(
      (g) => g.grade === parseInt(grade)
    );
    if (gradeData) {
      const subjectData = gradeData.subjects.find((s) => s.subject === subject);
      return subjectData ? subjectData.chapters : [];
    }
    return [];
  };

  // Get chapter name based on chapter number
  const getChapterName = (chapterNumber) => {
    const chapters = getChapters();
    const selectedChapter = chapters.find(
      (ch) => ch.chapterNo === parseInt(chapterNumber)
    );
    return selectedChapter ? selectedChapter.chapterName : "";
  };

  return (
    <>
      {loading && <LoadingScreen />}{" "}
      {/* Render LoadingScreen over the whole page */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "80vh", // Set max height for modal
            overflowY: "auto", // Enable vertical scroll if content exceeds maxHeight
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
            Generate Exam Paper
          </Typography>

          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Grade</InputLabel>
              <Select
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
                variant="filled"
              >
                {dropdownData.grades.map((g) => (
                  <MenuItem key={g.grade} value={g.grade}>
                    {g.grade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Subject</InputLabel>
              <Select
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                disabled={!grade}
                variant="filled"
              >
                {getSubjects().map((sub) => (
                  <MenuItem key={sub.subject} value={sub.subject}>
                    {sub.subject}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Difficulty</InputLabel>
              <Select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                variant="filled"
              >
                <MenuItem value="Easy">Easy</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Hard">Hard</MenuItem>
              </Select>
            </FormControl>

            {/* Add chapters */}
            {chapters.map((ch, index) => (
              <Box
                key={index}
                sx={{ mb: 2, p: 2, border: "1px solid #ccc", borderRadius: 2 }}
              >
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Chapter Number</InputLabel>
                  <Select
                    value={ch.chapterNumber}
                    onChange={(e) => {
                      const updatedChapters = [...chapters];
                      updatedChapters[index].chapterNumber = e.target.value;
                      updatedChapters[index].chapterName = getChapterName(
                        e.target.value
                      );
                      setChapters(updatedChapters);
                    }}
                    variant="filled"
                  >
                    {getChapters().map((chapter) => (
                      <MenuItem
                        key={chapter.chapterNo}
                        value={chapter.chapterNo}
                      >
                        {chapter.chapterNo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography sx={{ mb: 2 }}>
                  Chapter Name: {getChapterName(ch.chapterNumber)}
                </Typography>

                <TextField
                  label="MCQs"
                  fullWidth
                  value={ch.mcq}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].mcq = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Short Answers"
                  fullWidth
                  value={ch.shortAnswer}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].shortAnswer = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Long Answers"
                  fullWidth
                  value={ch.longAnswer}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].longAnswer = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Weightage"
                  fullWidth
                  value={ch.weightage}
                  onChange={(e) => {
                    const updatedChapters = [...chapters];
                    updatedChapters[index].weightage = e.target.value;
                    setChapters(updatedChapters);
                  }}
                  sx={{ mb: 2 }}
                />
              </Box>
            ))}

            <Button
              variant="contained"
              sx={{ mb: 2, backgroundColor: "#4CAF50", width: "100%" }}
              onClick={handleAddChapter}
            >
              Add Chapter
            </Button>

            <Button
              variant="contained"
              sx={{ backgroundColor: "#714B90", width: "100%" }}
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </Button>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default Modal4;
