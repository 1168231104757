import React from "react";
import Logo from "../assets/Logo.png";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMediaQuery } from "@mui/material"; // Import media query hook

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 600px)"); // Check for small screens
  const isVerySmallScreen = useMediaQuery("(max-width: 400px)"); // Check for very small screens

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" style={styles.appBar}>
      <Toolbar style={styles.toolbar}>
        <img
          src={Logo}
          alt="Company Logo"
          style={
            isVerySmallScreen
              ? styles.verySmallLogo
              : isSmallScreen
              ? styles.smallLogo
              : styles.logo
          } // Adjust logo for very small and small screens
        />
        {!isSmallScreen && (
          <Typography variant="h6" style={styles.title}></Typography>
        )}{" "}
        {/* Hide title on small screens */}
        <Typography
          style={isSmallScreen ? styles.smallContactUs : styles.contactUs}
        >
          Contact Us
        </Typography>
        {!isSmallScreen && (
          <Divider orientation="vertical" flexItem style={styles.divider} />
        )}
        <IconButton color="inherit" style={styles.icon}>
          <NotificationsIcon />
        </IconButton>
        {!isSmallScreen && (
          <Divider orientation="vertical" flexItem style={styles.divider} />
        )}
        <Avatar style={styles.avatar}>AB</Avatar>
        <IconButton
          color="inherit"
          onClick={handleMenu}
          style={styles.accountIcon}
        >
          <ArrowDropDownIcon style={styles.icon} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} style={styles.menuItem}>
            Profile
          </MenuItem>
          <MenuItem onClick={handleClose} style={styles.menuItem}>
            My Account
          </MenuItem>
          <MenuItem onClick={handleClose} style={styles.menuItem}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  appBar: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid #e0e0e0",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1300, // Ensure the header stays above other elements
  },
  toolbar: {
    padding: "0 10px", // Add padding to make it more responsive
    display: "flex",
    justifyContent: "space-between", // Distribute elements on the toolbar
    alignItems: "center", // Ensure vertical alignment
    overflow: "hidden", // Prevent overflow of toolbar content
  },
  logo: {
    height: "40px", // Default logo height
    marginRight: "10px",
  },
  smallLogo: {
    height: "20px", // Adjusted for smaller screens
    marginRight: "15px",
  },
  verySmallLogo: {
    height: "20px", // Adjusted for very small screens
    marginRight: "3px",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  contactUs: {
    marginRight: "15px",
    color: "grey",
  },
  smallContactUs: {
    fontSize: "0.8rem", // Smaller font size for small screens
    marginRight: "5px",
    color: "black",
  },
  divider: {
    backgroundColor: "grey",
    margin: "0 10px",
  },
  icon: {
    color: "black",
  },
  avatar: {
    marginRight: "10px",
    backgroundColor: "#714B90",
  },
  accountIcon: {
    marginRight: "15px",
  },
  menuItem: {
    color: "black",
  },
};

export default Header;
