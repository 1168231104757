const API_BASE_URL = "https://eduagents-api.leoqui.ai/api"; // Change this to your actual base URL
const API_BASE_URL1 = "https://agents-api-stage.leoqui.ai/api";

const API_ENDPOINTS = {
  quizGeneratorAPI: `${API_BASE_URL1}/enduser/end-user-chat`,
  examGeneratorAPI: `${API_BASE_URL}/exampaper/generate-exam-paper`,
  lessonPlanGenVideo: `${API_BASE_URL}/transcriber/generate-lesson-plan-from-video`,
  lessonPlanGen: `${API_BASE_URL}/lessonplan/generate-lesson-plan`,
};

export default API_ENDPOINTS;
