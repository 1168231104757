import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

const thoughts = [
  {
    quote: "You have to dream before your dreams can come true.",
    author: "APJ Abdul Kalam",
  },
  {
    quote: "Success is when your signature changes to an autograph.",
    author: "APJ Abdul Kalam",
  },
  {
    quote: "If you want to shine like a sun, first burn like a sun.",
    author: "APJ Abdul Kalam",
  },
  {
    quote:
      "Dream is not that which you see in sleep, it is something that does not let you sleep.",
    author: "APJ Abdul Kalam",
  },
  {
    quote: "What we think, we become.",
    author: "Buddha",
  },
  {
    quote:
      "Live as if you were to die tomorrow. Learn as if you were to live forever.",
    author: "Mahatma Gandhi",
  },
  {
    quote:
      "The best way to find yourself is to lose yourself in the service of others.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "An ounce of practice is worth more than tons of preaching.",
    author: "Mahatma Gandhi",
  },
  {
    quote:
      "Strength does not come from physical capacity. It comes from an indomitable will.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "In a gentle way, you can shake the world.",
    author: "Mahatma Gandhi",
  },
  {
    quote: "Arise, awake, and stop not until the goal is reached.",
    author: "Swami Vivekananda",
  },
  {
    quote: "The greatest sin is to think yourself weak.",
    author: "Swami Vivekananda",
  },
  {
    quote: "Believe in yourself and the world will be at your feet.",
    author: "Swami Vivekananda",
  },
  {
    quote: "You cannot believe in God until you believe in yourself.",
    author: "Swami Vivekananda",
  },
  {
    quote: "Education is the manifestation of the perfection already in man.",
    author: "Swami Vivekananda",
  },
  {
    quote:
      "शिक्षा सबसे शक्तिशाली हथियार है जिसका उपयोग आप दुनिया को बदलने के लिए कर सकते हैं।",
    author: "नेल्सन मंडेला",
  },
  {
    quote: "जिसे आप अपने दिल में रखते हैं, वही आपके लिए सबसे महत्वपूर्ण है।",
    author: "Anonymous",
  },
  {
    quote:
      "सपने वो नहीं जो हम सोते वक्त देखते हैं, सपने वो हैं जो हमें सोने नहीं देते।",
    author: "APJ Abdul Kalam",
  },
  {
    quote: "शिक्षा का उद्देश्य आत्मनिर्भरता और जागरूकता है।",
    author: "Anonymous",
  },
  {
    quote:
      "सच्ची शिक्षा वही है, जो मनुष्य को उसके कर्तव्यों का ज्ञान कराती है।",
    author: "Anonymous",
  },
  {
    quote: "शिक्षा आत्मा का विकास करती है और समाज को समृद्ध बनाती है।",
    author: "Anonymous",
  },
];

const LoaderWithThought = () => {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");

  useEffect(() => {
    // Randomly select a thought from the array
    const randomIndex = Math.floor(Math.random() * thoughts.length);
    const randomThought = thoughts[randomIndex];

    setQuote(randomThought.quote); // Set the quote text
    setAuthor(randomThought.author); // Set the author

    // Simulate loader (e.g., API call or other processing)
    const timer = setTimeout(() => {
      // You can add logic here if needed after the loading time
    }, 5000); // 5 seconds delay for the loader

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "#714B90",
      }}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
        {quote ? `"${quote}" - ${author}` : "Loading educational thoughts..."}
      </Typography>
    </Box>
  );
};

export default LoaderWithThought;
