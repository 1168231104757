// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Helvetica, sans-serif", // Set global font family
    h1: {
      fontFamily: "Montserrat, Helvetica, sans-serif",
    },
    h2: {
      fontFamily: "Montserrat, Helvetica, sans-serif",
    },
    body1: {
      fontFamily: "Montserrat, Helvetica, sans-serif",
    },
    button: {
      fontFamily: "Montserrat, Helvetica, sans-serif",
    },
  },
});

export default theme;
