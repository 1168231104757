import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Hook to access passed state and navigate
import ReactMarkdown from "react-markdown"; // For rendering markdown
import { Button, Box } from "@mui/material";
import html2pdf from "html2pdf.js"; // For exporting as PDF
import Header from "./Header"; // Assuming you have a Header component
import Logo from "../assets/Logo.png";

const LessonPlanGenerator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lessonPlan, lessonPlanURL } = location.state || {}; // Access lesson plan and lesson plan URL data from the state
  const contentToExportRef = useRef(null); // Ref for the content to export as PDF

  // Function to extract messages for lessonPlan
  const extractMessagesFromLessonPlan = (data) => {
    const messages = {};

    if (data?.ANSWER?.["Lesson Plan"]?.data?.[0]?.length) {
      messages.lessonPlanMessage =
        data.ANSWER["Lesson Plan"].data[0][0]?.message;
    }

    if (data?.ANSWER?.Quiz?.data?.[0]?.length) {
      messages.quizMessage = data.ANSWER.Quiz.data[0][0]?.message;
    }

    if (data?.ANSWER?.Assignment?.data?.[0]?.length) {
      messages.assignmentMessage = data.ANSWER.Assignment.data[0][0]?.message;
    }

    return messages;
  };

  // Function to extract messages for lessonPlanURL (since it has a different structure)
  const extractMessagesFromLessonPlanURL = (data) => {
    const messages = {};

    if (data?.["Lesson Plan"]?.data?.[0]?.length) {
      messages.lessonPlanMessage = data["Lesson Plan"].data[0][0]?.message;
    }

    if (data?.Quiz?.data?.[0]?.length) {
      messages.quizMessage = data.Quiz.data[0][0]?.message;
    }

    if (data?.Assignment?.data?.[0]?.length) {
      messages.assignmentMessage = data.Assignment.data[0][0]?.message;
    }

    return messages;
  };

  // Decide whether to use lessonPlan or lessonPlanURL for extraction
  const extractedMessages = lessonPlan
    ? extractMessagesFromLessonPlan(lessonPlan.data || {})
    : extractMessagesFromLessonPlanURL(lessonPlanURL?.data || {});

  const { lessonPlanMessage, quizMessage, assignmentMessage } =
    extractedMessages;

  // Function to handle PDF export
  const handleExportAsPDF = () => {
    const element = contentToExportRef.current; // Get the content from ref

    // Dynamically add the logo to the top of the content
    const logoElement = document.createElement("img");
    logoElement.src = Logo;
    logoElement.alt = "Logo";
    logoElement.style.width = "150px";
    logoElement.style.marginBottom = "20px";

    // Insert the logo at the top of the content
    element.prepend(logoElement);

    const options = {
      margin: 0.5,
      filename: "lesson_plan.pdf",
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Export the content as a PDF
    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => {
        // Remove the logo after export
        element.removeChild(logoElement);
      });
  };

  return (
    <div style={{ backgroundColor: "#FEFBF4", minHeight: "100vh" }}>
      {/* Include the Header */}
      <Header />

      <Box sx={{ padding: "64px" }}>
        {/* Content to export as PDF */}
        <div ref={contentToExportRef}>
          {/* <h1>Generated Lesson Plan</h1> */}

          {/* Display the Lesson Plan message with ReactMarkdown */}
          {lessonPlanMessage ? (
            <div>
              {/* <h2>Lesson Plan</h2> */}
              <ReactMarkdown>{lessonPlanMessage}</ReactMarkdown>
            </div>
          ) : (
            <p>No Lesson Plan data available.</p>
          )}

          {/* Display the Quiz message with ReactMarkdown */}
          {quizMessage ? (
            <div>
              <h2>Quiz</h2>
              <ReactMarkdown>{quizMessage}</ReactMarkdown>
            </div>
          ) : (
            <p>No Quiz data available.</p>
          )}

          {/* Display the Assignment message with ReactMarkdown */}
          {assignmentMessage ? (
            <div>
              <h2>Assignment</h2>
              <ReactMarkdown>{assignmentMessage}</ReactMarkdown>
            </div>
          ) : (
            <p>No Assignment data available.</p>
          )}
        </div>

        {/* Buttons (not included in the PDF) */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => navigate("/")}
        >
          Go to Homepage
        </Button>

        {/* Export as PDF Button */}
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mr: 2 }}
          onClick={handleExportAsPDF}
        >
          Export as PDF
        </Button>

        {/* Save and Download Button (dead for now) */}
        <Button variant="contained" color="warning" sx={{ mt: 2 }} disabled>
          Save and Download
        </Button>
      </Box>
    </div>
  );
};

export default LessonPlanGenerator;
